import { Navigate, Routes, Route } from "react-router-dom";

import { Drawer, useBreakpoints } from "cerulean";

import styles from "./CardManagerDrawer.module.scss";
import ManageACard from "./ManageACard/ManageACard";
import TravelNotice from "./TravelNotice/TravelNotice";

export interface CardManagerDrawerProps {
  isOpen: boolean;
  card: API.Card | null;
  onUserDismiss: () => void;
  updateCardStateById: (cardId: string, cardState: API.Card["state"]) => void;
}

const CardManagerDrawer = ({
  isOpen,
  card,
  onUserDismiss,
  updateCardStateById,
}: CardManagerDrawerProps) => {
  const { s, m } = useBreakpoints();

  if (isOpen && !card) {
    return <Navigate to="/cards" replace={true} />;
  }

  if (!card) {
    return null;
  }

  return (
    <Drawer
      depth="470px"
      isOpen={isOpen}
      onUserDismiss={onUserDismiss}
      showControls={false}
      paddingSize={!s || (s && !m) ? "none" : "xl"}
    >
      <div className={styles.cardManagerDrawerContainer}>
        <Routes>
          <Route
            path="/"
            element={
              <ManageACard
                card={card}
                onUserDismiss={onUserDismiss}
                updateCardStateById={updateCardStateById}
              />
            }
          />
          <Route
            path="/travel_notice"
            element={<TravelNotice card={card} onUserDismiss={onUserDismiss} />}
          />
        </Routes>
      </div>
    </Drawer>
  );
};

export default CardManagerDrawer;
