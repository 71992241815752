import { useCallback, useEffect } from "react";

import { useSelector } from "react-redux";
import _ from "lodash";

import { useObjectMemo } from "../../../../hooks";
import { ACCOUNT_TYPE_OPTIONS } from "../consts";
import {
  ACHDestinationForm,
  ACHPaymentRecipientForm,
  RecipientProfileForm,
  WireDestinationForm,
  transformRecipientApiFieldsToFormFields,
} from "../forms";
import { selectOneRecipient, selectSortedRecipients } from "../selectors";
import { isAchRecipient } from "../../helpers/recipient.helpers";
import {
  useCreateACHDestination,
  useCreateOrUpdateRecipientProfile,
  useCreateWireDestination,
} from "../requests";

export const useAccountTypeOptions = () => {
  return Object.values(ACCOUNT_TYPE_OPTIONS);
};

export const useProfilePurposeOptions = () => {
  return {
    Person: "person",
    Business: "business",
  };
};

export const useACHDestinationForm = () => {
  const { createACHDestination } = useCreateACHDestination();
  const form = ACHDestinationForm.useForm();
  const { submitForm } = form;

  const handleSubmit = useCallback(
    async (recipientId: API.RecipientId, onSuccess?: () => void) => {
      // validate form
      const result = await submitForm();
      if (result.success) {
        // passing in `onSuccess` does some magic in createACHDestionation's `send`
        // `send` will call `onSuccess`
        createACHDestination(recipientId, { onSuccess });
      }
    },
    [submitForm, createACHDestination],
  );

  return useObjectMemo({
    handleSubmit,
    form,
  });
};

export const useWireDestinationForm = () => {
  const { createWireDestination } = useCreateWireDestination();
  const form = WireDestinationForm.useForm();
  const { submitForm } = form;

  const handleSubmit = useCallback(
    async (recipientId: API.RecipientId, onSuccess?: () => void) => {
      // validate form
      const result = await submitForm();
      if (result.success) {
        // passing in `onSuccess` does some magic in createWireDestionation's `send`
        // `send` will call `onSuccess`
        createWireDestination(recipientId, { onSuccess });
      }
    },
    [submitForm, createWireDestination],
  );

  return useObjectMemo({
    handleSubmit,
    form,
  });
};

export const useACHPaymentRecipientForm = () => {
  const form = ACHPaymentRecipientForm.useForm();
  const { submitForm } = form;

  return useObjectMemo({
    form,
    submitForm,
  });
};

export const useRecipientProfileForm = (recipientId?: API.RecipientId) => {
  const form = RecipientProfileForm.useForm();
  const { submitForm, resetForm } = form;
  const { createOrUpdateRecipientProfile, error } =
    useCreateOrUpdateRecipientProfile();
  const recipient = useRecipient(recipientId) as API.Recipient;

  useEffect(() => {
    if (recipientId) {
      const values = transformRecipientApiFieldsToFormFields(recipient);
      resetForm({ values });
    }
  }, [recipientId, resetForm, recipient]);

  const handleSubmit = useCallback(
    async (onSuccess?: (data: API.CreateRecipientProfile.Response) => void) => {
      // validate form
      const result = await submitForm();
      if (result.success) {
        // passing in `onSuccess` does some magic in createACHProfile's `send`
        // `send` will call `onSuccess`
        createOrUpdateRecipientProfile(recipientId, { onSuccess });
      }
    },
    [submitForm, createOrUpdateRecipientProfile, recipientId],
  );

  return useObjectMemo({
    form,
    handleSubmit,
    error,
  });
};

export const useRecipients = () => {
  return useSelector(selectSortedRecipients);
};

export const useRecipient = (recipientId?: API.RecipientId) => {
  return useSelector(
    (state) => selectOneRecipient(state, recipientId),
    _.isEqual,
  );
};

export const useSelectedRecipient = () => {
  const {
    values: { selectedRecipientId },
  } = ACHPaymentRecipientForm.useForm();
  return useRecipient(selectedRecipientId);
};

export const useHandleRecipientPageSubmit = ({
  onSubmitSuccessful,
}: {
  onSubmitSuccessful: () => void;
}) => {
  const selectedRecipient = useSelectedRecipient();
  const { submitForm: submitPaymentRecipientForm } =
    useACHPaymentRecipientForm();
  const { handleSubmit: submitPaymentDetailForm } = useACHDestinationForm();

  const handleRecipientPageSubmit = useCallback(async () => {
    const { success: recipientSuccess } = await submitPaymentRecipientForm();

    if (!selectedRecipient) {
      return;
    }

    // If the recipient is an ACH recipient, and the recipient form
    // passes validation, continue on to submit the payment details form.
    if (isAchRecipient(selectedRecipient) && recipientSuccess) {
      onSubmitSuccessful();
      return;
    }

    // if recipient is wire recipient, validate payment details form and create ACH destination
    if (recipientSuccess) {
      submitPaymentDetailForm(selectedRecipient.id, onSubmitSuccessful);
    }
  }, [
    selectedRecipient,
    submitPaymentDetailForm,
    submitPaymentRecipientForm,
    onSubmitSuccessful,
  ]);

  return handleRecipientPageSubmit;
};
