import { useLocalization } from "@fluent/react";

import { Alert } from "cerulean";

import CardImage from "../../CardImage";
import LockCard from "./LockCard";
import CardActions from "./CardActions";

import styles from "./ManageACard.module.scss";

interface ManageACardProps {
  card: API.Card;
  updateCardStateById: (cardId: string, cardState: API.Card["state"]) => void;
  onUserDismiss: () => void;
}

const ManageACard = ({
  card,
  updateCardStateById,
  onUserDismiss,
}: ManageACardProps) => {
  const { l10n } = useLocalization();
  const isLocked = card?.state === "locked";

  return (
    <>
      <div className={styles.cardImageContainer}>
        <CardImage
          name={card.name}
          textColor={card.text_color}
          size="large"
          isLocked={isLocked}
          image={card.image}
          lastFourDigits={card.last_four_digits}
          network={card.network}
        />
      </div>
      {card.state === "damaged" && (
        <div className={styles.bannerInfo}>
          <Alert isActive={true} isDismissable={false} kind="info">
            {l10n.getString("card-controls-damaged-card-drawer-description")}
          </Alert>
        </div>
      )}
      <LockCard
        state={card.state}
        isLocked={isLocked}
        cardId={card.id}
        updateCardStateById={updateCardStateById}
      />
      <CardActions
        cardId={card.id}
        state={card.state}
        onUserDismiss={onUserDismiss}
      />
    </>
  );
};

export default ManageACard;
