import React from "react";
import { Row, IconButton } from "@narmi/design_system";

function TableComponent({ children }: { children: React.ReactNode }) {
  return <table className="table">{children}</table>;
}

function TableHeader({ children }: { children: React.ReactNode }) {
  return (
    <thead>
      <tr>{children}</tr>
    </thead>
  );
}

function TableHeaderCell({
  suffixIcon,
  onClick,
  children,
}: {
  suffixIcon?: string;
  onClick?: () => void;
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLTableCellElement>) {
  if (onClick) {
    return (
      <th onClick={onClick}>
        <Row gapSize="xxs" alignItems="center">
          <Row.Item shrink>{children}</Row.Item>
          <Row.Item shrink>
            <IconButton name={suffixIcon} />
          </Row.Item>
        </Row>
      </th>
    );
  }

  return <th>{children}</th>;
}

function TableBody({ children }: { children: React.ReactNode }) {
  return <tbody>{children}</tbody>;
}

function TableRow({
  children,
  onClick,
  className,
  ...props
}: {
  onClick?: () => void;
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLTableRowElement>) {
  const combinedClassName = onClick
    ? ["clickable", className].filter(Boolean).join(" ")
    : className;
  return (
    <tr onClick={onClick} className={combinedClassName} {...props}>
      {children}
    </tr>
  );
}

function TableCell({ children, className }: { children: React.ReactNode; className?: string }) {
  return <td className={className}>{children}</td>;
}

TableComponent.Header = TableHeader;
TableComponent.HeaderCell = TableHeaderCell;
TableComponent.Body = TableBody;
TableComponent.Row = TableRow;
TableComponent.Cell = TableCell;

export const Table = TableComponent;
