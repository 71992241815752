import * as Yup from "yup";

import { createForm } from "../../../forms";

export type RecipientProfileType = {
  name: string;
  nickname?: string;
  type: string;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required").max(35),
  nickname: Yup.string().max(256),
  type: Yup.string().required("Required"),
});

export const recipientProfileInitialValues: RecipientProfileType = {
  name: "",
  nickname: "",
  type: "",
};

export const transformRecipientApiFieldsToFormFields = ({
  id,
  name,
  nickname,
  type,
  ach_destination: achDestination,
  fedwire_destination: fedwireDestination,
}: API.Recipient) => ({
  id,
  name,
  nickname,
  type,
  achDestination,
  fedwireDestination,
});

// define form schema and validations
export const RecipientProfileForm = createForm({
  initialValues: recipientProfileInitialValues,
  validationSchema,
});
