import type { RecipientProfileType } from "./forms";
import type { NetworkPayload } from "../../../composites";

export const API = {
  fetchRecipients: (): NetworkPayload => {
    return {
      url: "recipients/",
      options: {
        method: "GET",
      },
    };
  },

  createOrUpdateRecipientProfile: (
    { name, nickname, type }: RecipientProfileType,
    recipientId?: API.RecipientId,
  ): NetworkPayload => {
    return {
      url: `recipients/${recipientId ? `${recipientId}/` : ""}`,
      options: {
        method: recipientId ? "PUT" : "POST",
        payload: {
          name,
          nickname,
          type,
        },
      },
    };
  },
};
