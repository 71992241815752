import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useLocalization, Localized } from "@fluent/react";
import { RadioButtons, Button } from "@narmi/design_system";
import { ContextForm, CsrfTokenMiddleware } from "cerulean";
import "./authentication.css";

const MfaDeviceSelect = ({ otpDeviceChoices }) => {
  const { l10n } = useLocalization();
  const form = useRef(null);
  const tfaRequiredButNotDevice = otpDeviceChoices.length === 0;

  const queryParams = new URLSearchParams(window.location.search);
  const redirectUrl = queryParams.get("next")
    ? encodeURIComponent(queryParams.get("next"))
    : "/";

  return (
    <div className="authentication-container">
      {!tfaRequiredButNotDevice ? (
        <form
          ref={form}
          action={`/login/verify_device_select?next=${redirectUrl}`}
          autoComplete="off"
          method="post"
        >
          <Localized id="heading-verify-account">
            <h1 data-testid="mfa-verify-header">
              Let&apos;s verify your account
            </h1>
          </Localized>
          <div>
            <Localized id="subhead-choose-verification">
              How would you like to receive your verification code?
            </Localized>
          </div>
          <CsrfTokenMiddleware />
          <div className="verify-device-options">
            <RadioButtons
              name="otp_device"
              initialValue={otpDeviceChoices[0][0]}
              options={otpDeviceChoices.reduce(
                (a, v) => ({ ...a, [v[1]]: v[0] }),
                {}
              )}
            />
          </div>
          <ContextForm.ActionBar>
            <Button
              as="button"
              onClick={(e) => {
                e.preventDefault();
                form.current.submit();
              }}
              label={l10n.getString("button-continue", null, "Continue")}
            />
          </ContextForm.ActionBar>
        </form>
      ) : (
        <Localized id="error-no-devices">
          <p className="fontSize--heading3">You do not have any two-factor authentication devices. Please contact support for assistance.</p>
        </Localized>
      )}
    </div>
  );
};
MfaDeviceSelect.propTypes = {
  otpDeviceChoices: PropTypes.array,
};

export default MfaDeviceSelect;
