const getLastFour = (num?: string) => {
  if (!num) {
    return "";
  }
  const accountAndShareId = num.split("=") || [""];
  const accountNumber = accountAndShareId[0];
  if (accountNumber.length <= 4) return num;
  // show suffix if it has one, else last four of number
  return accountAndShareId.length > 1
    ? `${accountNumber.substring(accountNumber.length - 2)}=${
        accountAndShareId[1]
      }`
    : accountNumber.substring(accountNumber.length - 4);
};

type Props = {
  accountNumber?: string;
  destinationInstitution?: string;
};

export const getDestinationAccountPreview = ({
  accountNumber,
  destinationInstitution = "Unknown Institution",
}: Props) => {
  const lastFour = getLastFour(accountNumber);
  const accountMask = lastFour ? `-**${lastFour}` : "";
  return `${destinationInstitution}${accountMask}`;
};
