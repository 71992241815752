export const LIMIT_TYPES = {
  ACH_PUSH: "ach_push",
  ACH_PULL: "ach_pull",
  ACH_PULL_NOW: "immediate_ach_pull",
  RDC: "rdc",
  RDC_NOW: "immediate_rdc",
  WIRE: "wire",
  ACH_PAYMENTS: "ach_payments",
} as const;

export const PRESET_LIMIT_TYPES = {
  transfer: ["ach_pull", "ach_push", "immediate_ach_pull"],
  wire: ["wire"],
  achPayments: ["ach_payments"],
} satisfies Record<string, API.Limits.Type[]>;
