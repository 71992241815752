import { useEffect } from "react";
import { useLocalization } from "@fluent/react";
import { catcat, useBreakpoints } from "cerulean";
import { entities } from "byzantine";

import BaseBanner from "../BaseBanner";
import ChartCard from "./ChartCard";
import ServicesCard from "./ServicesCard";
import TotalsCard from "./TotalsCard";
import AccountBalances from "./AccountBalances";
import RecentTransactionsCard from "./RecentTransactionsCard";
import MarketingCardContainer from "./MarketingCardContainer";
import SavvyMoneyWidget from "../savvy_money/SavvyMoneyWidget";
import { useCurrentUser } from "../contexts/CurrentUserContext";
import MarketingImage from "./MarketingImage";
import { Institution } from "../../types";

import style from "../DualColumnLayout.module.scss";

interface DashboardProps {
  institution: Institution;
  features: API.Features;
  replyTime: string;
}

const Dashboard = ({ institution, features, replyTime }: DashboardProps) => {
  const { l10n } = useLocalization();
  const { currentUser } = useCurrentUser();
  const userName =
    currentUser?.org_name || currentUser?.getShortDescription() || "";
  const { apps } = institution;
  const { l } = useBreakpoints();
  const { send: fetchSavvyMoneyRedirectUrl } =
    entities.savvyMoney.useFetchSavvyMoneyRedirectUrl(
      entities.savvyMoney.SavvyMoneyViewUrl.WebWidget,
      Boolean(features.olb_enable_credit_monitor),
    );

  useEffect(() => {
    fetchSavvyMoneyRedirectUrl();
  }, []);

  const marketingCardImage = (
    <MarketingImage address="b3bbe7e6-f68e-45e3-8124-691e18a4a3ca" />
  );
  const marketingBannerImage = (
    <MarketingImage address="fb06f1b7-87ff-4fcf-8a98-51a7fb4ba36d" />
  );

  return (
    <div className="dashboard-page-container">
      <BaseBanner
        bannerName={l10n.getString("dashboard-welcome", { userName })}
        buttonFunction={() => window.location.assign("/transfer/")}
        buttonName={l10n.getString("button-transfer", null, "Transfer funds")}
        orFeatureFlagChecks={["transfers"]}
        features={features}
      />
      <div className={style.pageLayout}>
        <div className={catcat(style.leftColumn, style.second)}>
          <AccountBalances />
          {!l && <MarketingCardContainer />}
          <RecentTransactionsCard replyTime={replyTime} />
          {l && marketingBannerImage}
        </div>
        <div className={catcat(style.rightColumn, style.first)}>
          <TotalsCard />
        </div>
        <div className={catcat(style.rightColumn, style.third)}>
          <ChartCard />
          {features.olb_enable_credit_monitor && <SavvyMoneyWidget />}
          {l && <MarketingCardContainer />}
          <ServicesCard services={apps} />
          {marketingCardImage}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
