import { useContext } from "react";
import { useLocalization } from "@fluent/react";
import { LangSelect } from "cerulean";
import { ContentCard } from "@narmi/design_system";
import type MembershipType from "byzantine/src/Membership";
import { useCurrentUser } from "../../../contexts/CurrentUserContext";
import { useUserFeatures } from "../../../contexts/UserFeaturesContext";
import { LangSelectContext } from "../../../../components/AppLocalizationProvider";
import EstatementsManager from "../../../estatements/EstatementsManager";
import LinkedAccountMembershipsCard from "../linked_account_memberships";
import PasswordInfoCard from "../PasswordInfoCard";
import BusinessInfoCard from "./BusinessInfoCard";
import ContactInfoCard from "./ContactInfoCard";

interface UserSettingsProfilePageProps {
  memberships: MembershipType[];
}

const UserSettingsProfilePage = ({
  memberships,
}: UserSettingsProfilePageProps) => {
  const { l10n } = useLocalization();
  const {
    isMultilingual,
    localeCodes,
    localeNames,
    selectedLocale,
    setSelectedLocale
  } = useContext(LangSelectContext)
  const userFeatures: {
    account_membership?: boolean; // eslint-disable-line camelcase
  } = useUserFeatures();
  const { currentUser } = useCurrentUser();
  const isBusinessUser = !!currentUser?.isBusiness();

  return (
    <div className="nds-grid margin--top--l">
      <div className="nds-span-6">
        <div className="nds-span-12">
          <ContactInfoCard />
        </div>
        {isBusinessUser && (
          <div className="nds-span-12 margin--top--l">
            <BusinessInfoCard />
          </div>
        )}
        {isMultilingual && (
          <div className="nds-span-12 margin--top--l">
            <ContentCard kind="elevated">
              <h4 className="fontFamily--default fontSize--heading4 margin--bottom">
                {l10n.getString("heading-language")}
              </h4>
              <LangSelect
                localeCodes={localeCodes}
                localeNames={localeNames}
                selectedLocale={selectedLocale}
                onChange={(lang: string) => {
                  setSelectedLocale(lang);
                }}
              />
            </ContentCard>
          </div>
        )}
        {userFeatures?.account_membership && (
          <div className="nds-span-12 margin--top--l">
            <LinkedAccountMembershipsCard initialMemberships={memberships} />
          </div>
        )}
        <div className="nds-span-12 margin--top--l">
          <PasswordInfoCard />
        </div>
      </div>
      <div className="nds-span-6">
        <EstatementsManager />
      </div>
    </div>
  );
};

export default UserSettingsProfilePage;
