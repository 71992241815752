/* eslint-disable */
/* existing code does not conform to azul's lint config */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";

import { useTranslation } from "react-i18next";
import { InstitutionLogo } from "cerulean";

import { initReactI18next } from "react-i18next";
import i18next from "i18next";

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "en",
    nsSeparator: false,
    keySeparator: false,
    resources: {
      en: {
        translation: typeof window !== "undefined" ? window.azulI18nCatalog || {} : {},
      },
    },
  });

const useViewport = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return width;
};

const ExternalLink = ({ children, href, title = "" }) => {
  const { t } = useTranslation();
  return (
    <a
      className="external-link"
      target="_blank"
      rel="noopener noreferrer"
      title={t(title)}
      href={href}
    >
      {children}
    </a>
  );
};
ExternalLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  title: PropTypes.string,
};

const SocialMediaIcon = ({ title, href, iconClassName }) => {
  return (
    <span className="social-media-icon">
      <ExternalLink title={title} target="_blank" rel="noreferrer" href={href}>
        <i className={`socialIcon ${iconClassName}`}></i>
      </ExternalLink>
    </span>
  );
};
SocialMediaIcon.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
  iconClassName: PropTypes.string,
};

const AzulFooter = ({
  ehlLogoUrl,
  institutionSettings,
}) => {
  const { l10n } = useLocalization();
  const width = useViewport();
  const mobileBreakpoint = 768; // 768 is the standard max size of mobile (see $tablet)

  const Copyright = () => {
    const { t } = useTranslation();
    const {
      member_dif,
      dif_logo_url,
      equal_housing_logo_url,
      fdic_ncua_logo_url,
      custom_footer,
      type,
    } = institutionSettings;
    let equalHousingLogo;

    if (equal_housing_logo_url) {
      equalHousingLogo = equal_housing_logo_url;
    } else {
      equalHousingLogo = ehlLogoUrl;
    }

    let customFooter = `${new Date().getFullYear()} ${institutionSettings.long_name}`;
    if (custom_footer) {
      customFooter = `${new Date().getFullYear()} ${
        institutionSettings.long_name
      }, ${custom_footer}`;
    }

    const isCreditUnion = type === "credit_union";

    // We display either the Member DIF logo OR the equivalent text, so append the text if logo wasn't provided
    const needsDifText = member_dif && !dif_logo_url;
    const difText = () => {
      if (!needsDifText) return "";
      if (isCreditUnion) return " and member DIF";
      return " and DIF";
    };

    // We rely on translations to display "Insured by NCUA" or "member FDIC", but we need to unify translations in order to get this working on Cerulean
    // (see https://github.com/narmi/banking/issues/25650). Use translations instead once this is fixed
    const membershipText = `${isCreditUnion ? "Insured by NCUA" : "Member FDIC"}`;

    const governmentMembershipText = `${membershipText}${difText()}. Equal Housing Lender`;

    return (
      <div className="footer-copyright">
        <div>
          <p className="nds-p3">&copy; {customFooter}</p>
          <p className="nds-p3">{`${t(governmentMembershipText)}  `}</p>
          <p className="nds-p3">{l10n.getString("powered-by-narmi")}</p>
        </div>
        <div className="copyright-image">
          {equalHousingLogo && (
            <img
              src={equalHousingLogo}
              alt={l10n.getString("equal-housing-alt-text")}
              className="footer-ehl-logo"
            />
          )}
          {member_dif && dif_logo_url && (
            <img
              alt="Member Bank: Depositors Insurance Fund"
              src={dif_logo_url}
              style={{ marginLeft: "3px", width: "30px" }}
            />
          )}
          {fdic_ncua_logo_url && (
            <img
              alt="Member Bank: FDIC or NCUA"
              src={fdic_ncua_logo_url}
              style={{ marginLeft: "3px", width: "48px" }}
            />
          )}
        </div>
      </div>
    );
  };

  const RateDefinitions = ({ isMobile = false }) => {
    const { t } = useTranslation();
    const { l10n } = useLocalization();
    return (
      <div className="footer-rate-definitions">
        <p className="nds-p3 medium-grey">
          {isMobile ? (
            <>
              {l10n.getString("apr-label-footer")}
              <br />
              {l10n.getString("apy-label-footer")}
            </>
          ) : (
            <>{`${l10n.getString("apy-label-footer")}. ${l10n.getString("apr-label-footer")}.`}</>
          )}
        </p>
      </div>
    );
  };
  RateDefinitions.propTypes = {
    isMobile: PropTypes.bool,
  };

  const BankInfo = () => {
    const { routing_number, location_hours_url, terms_url, privacy_url } = institutionSettings;
    return (
      <>
        <p className="nds-subheader">Info</p>
        <p className="nds-p3">Routing number: {routing_number}</p>
        {location_hours_url && (
          <p className="nds-p3">
            <ExternalLink href={location_hours_url}>View locations and hours</ExternalLink>
          </p>
        )}
        <p className="nds-p3">
          <ExternalLink href={terms_url}>Terms</ExternalLink>
        </p>
        <p className="nds-p3">
          <ExternalLink href={privacy_url}>{l10n.getString("disclosure-privacy")}</ExternalLink>
        </p>
      </>
    );
  };

  const ContactUs = () => {
    const { t } = useTranslation();
    const { help_phone, card_phone, lost_card_url, help_email, chat_script, chat_url } =
      institutionSettings;
    // Do not render if there's no info to display in Contact column (unlikely scenario)
    if (!help_phone && !card_phone && !lost_card_url && !(chat_script || chat_url || help_email))
      return null;
    return (
      <>
        <p className="nds-subheader">Contact Us</p>
        <p className="nds-p3">
          <a className="grey-link" href={`tel:${help_phone}`}>
            {t(l10n.getString("support-label-footer"))}: {help_phone}
          </a>
        </p>
        {(card_phone && (
          <p className="nds-p3">
            <a className="grey-link" href={`tel:${card_phone}`}>
              Lost card: {card_phone}
            </a>
          </p>
        )) ||
          (lost_card_url && (
            <p className="nds-p3">
              <a className="grey-link" href={lost_card_url}>
                Lost card?
              </a>
            </p>
          ))}
        {(chat_script && (
          <p className="nds-p3">
            <a onClick={() => eval(chat_script)}>Customer Service Chat</a>
          </p>
        )) ||
          (chat_url && (
            <p className="nds-p3">
              <ExternalLink href={chat_url}>Customer Service Chat</ExternalLink>
            </p>
          )) ||
          (help_email && (
            <p className="nds-p3">
              <a className="grey-link" href={`mailto:${help_email}`}>
                Email us: {help_email}
              </a>
            </p>
          ))}
      </>
    );
  };

  const SocialMediaIcons = () => {
    const { facebook, twitter, instagram, linkedin } = institutionSettings;
    return (
      <div className="social-media-icons">
        {facebook && (
          <SocialMediaIcon
            title="Facebook Page"
            href={facebook}
            iconClassName="narmi-icon-facebook"
          />
        )}
        {twitter && (
          <SocialMediaIcon title="Twitter Page" href={twitter} iconClassName="narmi-icon-twitter" />
        )}
        {instagram && (
          <SocialMediaIcon
            title="Instagram Page"
            href={instagram}
            iconClassName="narmi-icon-instagram"
          />
        )}
        {linkedin && (
          <SocialMediaIcon
            title="LinkedIn Page"
            href={linkedin}
            iconClassName="narmi-icon-linkedin"
          />
        )}
      </div>
    );
  };

  const MobileFooter = () => {
    return (
      <div className="nds-span-6">
        <InstitutionLogo
          className="footer-logo"
          institution={{
            logo: institutionSettings.logo_url,
            name: institutionSettings.long_name,
          }}
        />
        <div className="fi-information nds-span-6">
          <div className="fi-information-column"><BankInfo /></div>
          <div className="fi-information-column">
            <ContactUs />
            <SocialMediaIcons />
          </div>
        </div>
        <div>
          <Copyright ehlLogoUrl={ehlLogoUrl} />
          <RateDefinitions isMobile />
        </div>
      </div>
    );
  };

  const DesktopFooter = () => {
    return (
      <>
        <div className="nds-span-6">
          <InstitutionLogo
            className="footer-logo"
            institution={{
              logo: institutionSettings.logo_url,
              name: institutionSettings.long_name,
            }}
          />
          <Copyright />
          <RateDefinitions />
        </div>
        <div className="fi-information nds-span-6">
          <div className="fi-information-column"><BankInfo /></div>
          <div className="fi-information-column">
            <ContactUs />
            <SocialMediaIcons />
          </div>
        </div>
      </>
    );
  };
  // The footer design has different order for narrow vs. wide screens
  return (
    <div className="narmi-grid-container grid-footer" style={{ flexGrow: 1 }}>
      <div
        className="footer-container background-db narmi-grid-content"
      >
        <div className="nds-grid">
          {width < mobileBreakpoint ? <MobileFooter /> : <DesktopFooter />}
        </div>
      </div>
    </div>
  );
};
AzulFooter.propTypes = {
  institutionSettings: PropTypes.object.isRequired,
  ehlLogoUrl: PropTypes.string,
};

export default AzulFooter;
