import { useEffect } from "react";
import { useLocalization } from "@fluent/react";
import { entities } from "byzantine";
import {
  TextInput,
  AccountNumberTextInput,
  RoutingNumberTextInput,
  StateSelector,
  ZipCodeTextInput,
  AutocompleteStreetAddress,
} from "cerulean";
import {
  CollapsibleCard,
  ResponsiveFlex,
  Button,
  Row,
} from "@narmi/design_system";
import Address from "byzantine/src/Address";
import { WireSectionProps } from "./RecipientDrawer";
import * as formAdapters from "../../../../src/adapters";

const WireDestinationSection = ({
  title,
  isOpen,
  isDisabled,
  onClose,
  onSave,
  onOpen,
  savedACHDestination,
  recipientId,
}: WireSectionProps) => {
  const { l10n } = useLocalization();
  const { form, handleSubmit } = entities.recipients.useWireDestinationForm();
  const { form: achDestinationForm } =
    entities.recipients.useACHDestinationForm();
  const isAutocompleteApiAvailable = !!(window as any).google?.maps;

  const handleClickSave = async () => {
    handleSubmit(recipientId as API.RecipientId, () => {
      // we have to "reset" the form so the form reset's dirty value after being saved
      form.resetForm({ values: form.values });
      onSave();
    });
  };

  useEffect(() => {
    form.resetForm({
      values: entities.recipients.wireDestinationInitialValues,
    });
  }, []);

  const { onChange, ...restOfStreetAddressFieldProps } =
    formAdapters.fieldWithOnChange(form, "address.streetAddress");

  const handleOnAddressUpdate = (value: Address) => {
    const {
      street_address: streetAddress,
      city,
      region_code: regionCode,
      postal_code: postalCode,
    } = value;
    form.setFieldValue("address.streetAddress", streetAddress);
    form.setFieldValue("address.city", city);
    form.setFieldValue("address.regionCode", regionCode);
    form.setFieldValue("address.postalCode", postalCode);
  };

  const handleClickUseACH = () => {
    const { accountNumber, routingNumber } = achDestinationForm.values;
    form.setFieldValue("accountNumber", accountNumber);
    form.setFieldValue("routingNumber", routingNumber);
  };

  return (
    <CollapsibleCard
      onClose={onClose}
      onOpen={onOpen}
      title={title}
      isDisabled={isDisabled}
      isOpen={isOpen}
      trigger="caret-end"
    >
      <ResponsiveFlex gapSize="s">
        {savedACHDestination ? (
          <Row justifyContent="end">
            <Row.Item shrink>
              <Button kind="plain" onClick={handleClickUseACH}>
                Use ACH details
              </Button>
            </Row.Item>
          </Row>
        ) : null}
        <AccountNumberTextInput
          label={l10n.getString("labelAccountNumber", null, "Account number")}
          {...formAdapters.fieldWithOnChange(form, "accountNumber")}
        />

        <RoutingNumberTextInput
          label={l10n.getString("label-routing", null, "Routing number")}
          {...formAdapters.fieldWithOnChange(form, "routingNumber")}
        />
        <Row gapSize="xs">
          <Row.Item>
            {isAutocompleteApiAvailable ? (
              <AutocompleteStreetAddress
                streetAddressLabel={l10n.getString(
                  "label-street-address",
                  null,
                  "Street address",
                )}
                onChange={onChange}
                onUpdate={handleOnAddressUpdate}
                {...restOfStreetAddressFieldProps}
              />
            ) : (
              <TextInput
                label={l10n.getString(
                  "label-street-address",
                  null,
                  "Street address",
                )}
                {...formAdapters.input(form, "address.streetAddress")}
              />
            )}
          </Row.Item>
          <Row.Item shrink>
            <TextInput
              label={l10n.getString(
                "label-address-2",
                null,
                "Apt/Suite (optional)",
              )}
              {...formAdapters.input(form, "address.streetAddress2")}
            />
          </Row.Item>
        </Row>
        <TextInput
          label={l10n.getString("label-city", null, "City")}
          {...formAdapters.input(form, "address.city")}
        />
        <Row gapSize="xs">
          <Row.Item>
            <StateSelector
              label={l10n.getString("label-state", null, "State")}
              {...formAdapters.fieldWithOnChange(form, "address.regionCode")}
            />
          </Row.Item>
          <Row.Item>
            <ZipCodeTextInput
              label={l10n.getString("label-postal", null, "Zip code")}
              {...formAdapters.fieldWithOnChange(form, "address.postalCode")}
            />
          </Row.Item>
        </Row>
        <Row justifyContent="end">
          <Row.Item shrink>
            <Button kind="primary" onClick={handleClickSave}>
              {l10n.getString("button-save")}
            </Button>
          </Row.Item>
        </Row>
      </ResponsiveFlex>
    </CollapsibleCard>
  );
};

export default WireDestinationSection;
