import type { PropsWithChildren } from "react";

import DrawerBottomBar from "./DrawerBottomBar";
import styles from "./DrawerLayout.module.scss";

interface DrawerLayoutProps {
  onSave: (callback?: (error?: Error) => void) => void;
  saveLabel?: string;
  isSaveDisabled?: boolean;
  isContextForm?: boolean;
  onCancel: () => void;
  isCancelDisabled?: boolean;
  loading?: boolean;
  isBottomBarHidden?: boolean;
}

// to be used inside a `Drawer` component when you need a fixed bottom bar in a drawer
// the parent `Drawer` component must have paddingSize="none"
const DrawerLayout = ({
  children,
  onSave,
  saveLabel,
  isSaveDisabled,
  isContextForm,
  onCancel,
  isCancelDisabled,
  loading,
  isBottomBarHidden,
}: PropsWithChildren<DrawerLayoutProps>) => (
  <div className={styles.drawerLayout}>
    <div className={styles.content}>{children}</div>
    {isBottomBarHidden ? null : (
      <DrawerBottomBar
        onSave={onSave}
        saveLabel={saveLabel}
        isSaveDisabled={isSaveDisabled}
        isContextForm={isContextForm}
        onCancel={onCancel}
        isCancelDisabled={isCancelDisabled}
        loading={loading}
      />
    )}
  </div>
);

export default DrawerLayout;
