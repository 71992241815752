import { useCallback } from "react";

import { useDispatch } from "react-redux";

import { useObjectMemo } from "../../../hooks";
import {
  useRequestWithFeedback,
  type OverrideRunProps,
} from "../../../composites";

import { API } from "./api";
import { actions } from "./slice";
import { RecipientProfileForm } from "./forms";
import {
  RECIPIENT_WITH_ACH_DESTINATION,
  RECIPIENT_WITH_WIRE_DESTINATION,
} from "./dummyData";

import type { BootstrapFetchRequest } from "../../bootstrap";

export const useFetchRecipients = () => {
  const dispatch = useDispatch();

  const { send, loading, status } =
    useRequestWithFeedback<API.FetchRecipients.Response>();

  const fetchRecipients = useCallback(
    (overrideRunProps: OverrideRunProps<API.FetchRecipients.Response> = {}) => {
      send({
        action: API.fetchRecipients(),
        onData: ({ results: recipients }) => {
          dispatch(actions.setMany(recipients));
        },
        ...overrideRunProps,
      });
    },
    [dispatch, send],
  );

  // when creating a hook that has an API request, has to follow the below convention
  return useObjectMemo({
    send: fetchRecipients,
    loading,
    error: status === "error",
    hasData: false,
  }) satisfies BootstrapFetchRequest;
};

export const useCreateACHDestination = () => {
  const { send, loading } = useRequestWithFeedback<
    API.CreateACHDestination.Response,
    API.CreateACHDestination.Error
  >();

  const dispatch = useDispatch();
  const createACHDestination = useCallback(
    (
      recipientId: API.RecipientId,
      overrideRunProps: OverrideRunProps<
        API.CreateACHDestination.Response,
        API.CreateACHDestination.Error
      > = {},
    ) => {
      send({
        // TODO: update with real API, add error handling
        action: API.fetchRecipients(),
        onData: (_) => {
          // update the store by updating the recipient's ach destination details
          dispatch(actions.updateOne(RECIPIENT_WITH_ACH_DESTINATION));
        },
        ...overrideRunProps,
      });
    },
    [dispatch, send],
  );

  return useObjectMemo({
    createACHDestination,
    loading,
  });
};

export const useCreateWireDestination = () => {
  const { send, loading } = useRequestWithFeedback<
    API.CreateWireDestination.Response,
    API.CreateWireDestination.Error
  >();

  const dispatch = useDispatch();
  const createWireDestination = useCallback(
    (
      recipientId: API.RecipientId,
      overrideRunProps: OverrideRunProps<
        API.CreateWireDestination.Response,
        API.CreateWireDestination.Error
      > = {},
    ) => {
      send({
        // TODO: update with real API, add error handling
        action: API.fetchRecipients(),
        onData: (_) => {
          // update the store by updating the recipient's wire destination details
          dispatch(actions.updateOne(RECIPIENT_WITH_WIRE_DESTINATION));
        },
        ...overrideRunProps,
      });
    },
    [dispatch, send],
  );

  return useObjectMemo({
    createWireDestination,
    loading,
  });
};

export const useCreateOrUpdateRecipientProfile = () => {
  const { send, loading, status } = useRequestWithFeedback<
    API.CreateRecipientProfile.Response,
    API.CreateRecipientProfile.Error
  >();
  const { values } = RecipientProfileForm.useForm();
  const dispatch = useDispatch();

  const createOrUpdateRecipientProfile = useCallback(
    (
      recipientId?: API.RecipientId,
      overrideRunProps: OverrideRunProps<
        API.CreateRecipientProfile.Response,
        API.CreateRecipientProfile.Error
      > = {},
    ) => {
      send({
        action: API.createOrUpdateRecipientProfile(values, recipientId),
        onData: (recipient) => {
          if (!recipientId) {
            dispatch(actions.setOne(recipient));
          } else {
            dispatch(actions.updateOne(recipient));
          }
        },
        ...overrideRunProps,
      });
    },
    [dispatch, send, values],
  );

  return useObjectMemo({
    createOrUpdateRecipientProfile,
    loading,
    error: status === "error",
  });
};
