import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RadioButtons, ProgressButtons } from "cerulean";
import { useLocalization } from "@fluent/react";

import style from "./ReplaceReasonPage.module.scss";
import HeaderWithSteps from "../../../../HeaderWithSteps";
import { useSudoContext } from "../../../../SudoContext";

const ReplaceReasonPage = ({ cardId }: { cardId: string }) => {
  const {
    totalSteps,
    formData,
    onFormDataChange,
    establishSudo,
    useSetInitialSteps,
  } = useSudoContext();
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();
  const { reason } = formData || { reason: "" };
  const { l10n } = useLocalization();

  useSetInitialSteps(2);

  const onChangeReason = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    onFormDataChange({ reason: event.target.value as API.CardReplaceReason });
  };

  const options = useMemo(() => {
    const reasons: API.CardReplaceReason[] = [
      "lost",
      "stolen",
      "damaged",
      "never-received",
    ];

    const reasonOptions: {
      [key: string]: { value: API.CardReplaceReason; details: string };
    } = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const reasonValue of reasons) {
      const reasonLabel = l10n.getString(`replace-card-reason-${reasonValue}`);
      reasonOptions[reasonLabel] = {
        value: reasonValue,
        details: l10n.getString(
          `replace-card-reason-${reasonValue}-description`,
        ),
      };
    }

    return reasonOptions;
  }, [l10n]);

  return (
    <>
      <HeaderWithSteps
        headerText={l10n.getString("screen-title-replace-card")}
        subheaderText={l10n.getString("replace-card-description")}
        step={1}
        totalSteps={totalSteps}
      />
      <div className={style.radioButtons}>
        <RadioButtons
          kind="card"
          field="replaceReason"
          onChange={onChangeReason}
          reason={reason}
          error={error}
          options={options}
        />
      </div>
      <div className={style.progressButtonsContainer}>
        <ProgressButtons
          backLabel={l10n.getString("button-back")}
          nextLabel={l10n.getString("button-continue")}
          onBack={() => {
            navigate("/settings/cards");
          }}
          onNext={() => {
            if (!reason) {
              setError(l10n.getString("replace-card-error-select-reason"));
              return;
            }
            establishSudo(`/cards/replace-a-card/${cardId}/confirmation`);
          }}
        />
      </div>
    </>
  );
};

export default ReplaceReasonPage;
