import * as Yup from "yup";

import { createForm } from "../../forms";

export type TravelNoteFormFields = {
  endDate: DateString | null;
  startDate: DateString | null;
  travelDestinationCodes: Record<string, string> | null;
};

const validationSchema = Yup.object({
  endDate: Yup.string()
    .required("End date required.")
    .nonNullable("End date required."),
  startDate: Yup.string()
    .required("Start date required.")
    .nonNullable("Start date required."),
  travelDestinationCodes: Yup.object().nonNullable("Destination required."),
});

const initialValues: TravelNoteFormFields = {
  endDate: null,
  startDate: null,
  travelDestinationCodes: null,
};

export const createTravelNoteForm = createForm({
  initialValues,
  validationSchema,
});
