import { Button } from "cerulean";

interface TravelNoticeProps {
  card: API.Card;
  onUserDismiss: () => void;
}

const TravelNotice = ({ card, onUserDismiss }: TravelNoticeProps) => (
  <>
    <h1>Travel Notice {card.id}</h1>
    <Button onClick={onUserDismiss} label="BYE" />
  </>
);

export default TravelNotice;
